import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { useState } from "react";
import { BsUiChecks, BsCheck } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { ImCheckboxUnchecked } from "react-icons/im";
import { MdOutlineDisabledByDefault } from "react-icons/md";
import { toast } from "react-toastify";
import { BsHandThumbsUp, BsHandIndexThumb, BsHandThumbsDown } from "react-icons/bs";
import purchaseSuccessSfx from "@static/sounds/purchaseSuccess.mp3";
import purchaseErrorSfx from "@static/sounds/purchaseError.mp3";
import useSound from "use-sound";
import Logo from "@components/common/Logo";

export default function Calculadora() {
    const [notaMaxima, setNotaMaxima] = useState(10);
    const [restarIncorrectas, setRestarIncorrectas] = useState(0);
    const [correctas, setCorrectas] = useState(0);
    const [incorrectas, setIncorrectas] = useState(0);
    const [enBlanco, setEnBlanco] = useState(0);
    const [nota, setNota] = useState(null);

    const [playSuccess] = useSound(purchaseSuccessSfx, {
        volume: 0.25,
    });
    const [playWrong] = useSound(purchaseErrorSfx, {
        volume: 0.25,
    });

    const handleSubmit = e => {
        e.preventDefault();

        const T = parseInt(correctas) + parseInt(incorrectas) + parseInt(enBlanco);
        const C = parseInt(correctas);
        const E = parseInt(incorrectas);
        const INC = parseInt(restarIncorrectas);
        const MAX = parseInt(notaMaxima);

        if (C < 0 || E < 0 || INC < 0 || MAX < 0) {
            playWrong();

            toast.error("Los datos que has introducido no tienen rangos aceptados.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });

            return;
        }

        if (T === 0) {
            toast.warning("Debes rellenar las respuestas correctas, incorrectas y en blanco.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });

            return;
        }

        // Calculamos la nota
        const resultado = (MAX / T) * C - (INC > 0 ? (MAX / (INC * T)) * E : 0);

        // Dependiendo del resultado hacemos sonar un sonido
        if (resultado / MAX >= 0.75) {
            playSuccess();
        } else if (resultado / MAX >= 0.5) {
            playSuccess();
        } else {
            playWrong();
        }

        // Seteamos nota
        setNota({
            resultado,
            notaMaxima,
            totalPreguntas: T,
            correctas: C,
            incorrectas: E,
            restarIncorrectas: INC,
        });
    };

    return (
        <Layout>
            <Seo
                title="Nuestra calculadora"
                description="Esta herramienta te mostrará tu puntuación en relación a la puntuación total del examen, ya sea sobre 10 u otra puntuación total."
                tags="ios,android,apps,oposiciones,policia,gub,correos"
            />
            <SmallBanner
                title="Calculadora de notas"
                subtitle="Esta herramienta te mostrará tu puntuación en relación a la
                puntuación total del examen, ya sea sobre 10 u otra
                puntuación total."
                filename={"bgs/bgCalc"}
            />

            <div className="container py-20">
                <Title>Calculadora de notas</Title>
                <Text>
                    En iOpos Academia, puedes seleccionar cualquiera de los métodos oficiales de
                    evaluación de las oposiciones o personalizar los valores para calcular la nota
                    de cualquier otro examen tipo test.
                    <br />
                    <br />
                    Esta herramienta te mostrará tu puntuación en relación a la puntuación total del
                    examen, ya sea sobre 10 u otra puntuación total.
                </Text>

                <div className="mt-10">
                    <form
                        onSubmit={handleSubmit}
                        className="md:p-10 md:rounded md:shadow md:border md:border-gray-300"
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                            {/** Nota máxima */}
                            <div className="space-y-2">
                                <label htmlFor="notaMaxima" className={styles.label}>
                                    <BsUiChecks className="text-green-700 w-5 h-5" />
                                    <span>Nota máxima:</span>
                                </label>

                                <input
                                    id="notaMaxima"
                                    type="number"
                                    onChange={e => setNotaMaxima(e.target.value)}
                                    min={0}
                                    max={200}
                                    required={true}
                                    className={styles.input}
                                    value={notaMaxima}
                                />

                                <p className={styles.spanComment}>
                                    Nota máxima que se puede conseguir en el examen.
                                </p>
                            </div>

                            {/** Respuestas incorrectas restan */}
                            <div className="space-y-2">
                                <label htmlFor="restarIncorrectas" className={styles.label}>
                                    <RxCross2 className="text-red-700 w-5 h-5" />
                                    <span>Respuestas incorrectas restan:</span>
                                </label>

                                <input
                                    id="restarIncorrectas"
                                    type="number"
                                    onChange={e => setRestarIncorrectas(e.target.value)}
                                    required={true}
                                    max={10}
                                    min={0}
                                    className={styles.input}
                                    value={restarIncorrectas}
                                />

                                <p className={styles.spanComment}>
                                    En el caso que las respuestas incorrectan resten has de
                                    introducir cada cuantas respuestas incorrectas invalidan una de
                                    correcta.
                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10">
                            {/** Respuestas correctas */}
                            <div className="space-y-2">
                                <label htmlFor="correctas" className={styles.label}>
                                    <BsCheck className="text-green-700 w-5 h-5" />
                                    <span>Respuestas correctas:</span>
                                </label>

                                <input
                                    id="correctas"
                                    type="number"
                                    onChange={e => setCorrectas(e.target.value)}
                                    required={true}
                                    max={100}
                                    min={0}
                                    className={styles.input}
                                    value={correctas}
                                />
                            </div>

                            {/** Respuestas incorrectas */}
                            <div className="space-y-2">
                                <label htmlFor="incorrectas" className={styles.label}>
                                    <MdOutlineDisabledByDefault className="text-red-700 w-5 h-5" />
                                    <span>Respuestas incorrectas:</span>
                                </label>

                                <input
                                    id="incorrectas"
                                    type="number"
                                    onChange={e => setIncorrectas(e.target.value)}
                                    required={true}
                                    max={100}
                                    min={0}
                                    className={styles.input}
                                    value={incorrectas}
                                />
                            </div>

                            {/** Respuestas correctas */}
                            <div className="space-y-2">
                                <label htmlFor="respuestasEnBlanco" className={styles.label}>
                                    <ImCheckboxUnchecked className="text-gray-700 w-4 h-4" />
                                    <span>Respuestas en blanco:</span>
                                </label>

                                <input
                                    id="respuestasEnBlanco"
                                    type="number"
                                    onChange={e => setEnBlanco(e.target.value)}
                                    required={true}
                                    max={100}
                                    min={0}
                                    className={styles.input}
                                    value={enBlanco}
                                />
                            </div>
                        </div>

                        <div className="mt-10 w-full">
                            <button
                                type="submit"
                                className={styles.submit}
                                title="¡Calcular la nota final!"
                            >
                                Calcular nota
                            </button>
                        </div>

                        {nota && (
                            <>
                                <div className={`border-t-2 border-gray-300 mt-10 pt-5`}>
                                    <div className="rounded-full shadow w-full relative bg-gray-600 overflow-hidden">
                                        <div className="absolute top-0 w-full left-0 h-full z-[1] flex items-center">
                                            <div
                                                className={`from-green-600 bg-gradient-to-r to-green-800 h-full animate-pulse duration-200`}
                                                style={{
                                                    width: `${
                                                        (nota.correctas / nota.totalPreguntas) * 100
                                                    }%`,
                                                }}
                                            />

                                            <div
                                                className={`to-red-800 from-red-600 bg-gradient-to-r h-full`}
                                                style={{
                                                    width: `${
                                                        (nota.incorrectas / nota.totalPreguntas) *
                                                        100
                                                    }%`,
                                                }}
                                            />
                                        </div>
                                        <div className="relative top-0 w-full left-0 h-full z-[2] flex items-center justify-center font-semibold text-xs py-2 text-white font-montserrat space-x-2">
                                            <span className="font-bold">Nota:</span>
                                            <span className="text-sm">
                                                {nota.resultado.toFixed(1)} sobre {nota.notaMaxima}
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        className={`mt-5 rounded px-5 py-3 bg-opacity-50 text-sm ${
                                            nota.resultado > 7.5
                                                ? "text-green-800 bg-green-500 border-green-800"
                                                : nota.resultado > 5
                                                  ? "text-yellow-800 bg-yellow-500 border-yellow-800"
                                                  : "text-red-800 bg-red-500 border-red-800"
                                        }`}
                                    >
                                        {nota.resultado / nota.notaMaxima >= 0.75 ? (
                                            <div className="flex items-center justify-center space-x-2">
                                                <BsHandThumbsUp className="w-5 h-5" />
                                                <span>
                                                    Felicidades, has obtenido una calificación
                                                    excelente en tu evaluación.
                                                </span>
                                            </div>
                                        ) : nota.resultado / nota.notaMaxima >= 0.5 ? (
                                            <div className="flex items-center justify-center space-x-2">
                                                <BsHandIndexThumb className="w-5 h-5" />
                                                <span>
                                                    Tu evaluación demuestra un desempeño
                                                    satisfactorio, pero aún hay oportunidades para
                                                    mejorar.
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center space-x-2">
                                                <BsHandThumbsDown className="w-5 h-5" />
                                                <span>
                                                    Tu evaluación indica que hay áreas en las que
                                                    necesitas mejorar para alcanzar tus objetivos
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex items-center justify-center mt-10">
                                        <Logo
                                            className="duration-300 w-20 md:w-32 mx-auto md:mx-0"
                                            alt="iopos logo"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </Layout>
    );
}

const styles = {
    label: "w-full block font-montserrat text-gray-700 font-semibold uppercase flex items-center space-x-2 text-sm",
    input: "font-montserrat w-full rounded-md bg-gray-200 text-gray-700 border border-gray-300 px-5 py-2 block",
    spanComment: "text-xs text-gray-500 font-montserrat mb-5",
    submit: "font-montserrat font-semibold text-center rounded-md shadow-md hover:shadow-xl py-4 bg-cpurple text-white text-xs uppercase duration-300 w-full block cursor-pointer",
};
